// layout container home media slider
.homeMediaSlider {
  
  width: calc(100vw);
  margin-left: calc((100vw - 1200px)/-2);
  .page-manager-visible & {
    width: calc(100vw - 60px);
    margin-left: calc((100vw - 1260px)/-2);
  }
  @media screen and (max-width:1260px) {
    .page-manager-visible & {
      width: 100%;
      margin-left: 0;
    }
  }
  @media screen and (max-width:1200px) {
    width: 100%;
    margin-left: 0;
  }
  
  // mediaSlider
  .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mediaSlider {
    margin: 0px 0;
    // title
    
    > h2 {
      display:none;
    }
    
    // description
    
    > p {
      display:none;
    }
    
    // pagination
    
    .slider-pagination {
      display: none;
    }
    .sn-media-slider .slider.top-left-paging,
    .sn-media-slider .slider.top-center-paging,
    .sn-media-slider .slider.top-right-paging
    .sn-media-slider .slider.bottom-left-paging,
    .sn-media-slider .slider.bottom-center-paging,
    .sn-media-slider .slider.bottom-right-paging {
      margin: 0;
    }
    
    // sn-media-slider
    .sn-media-slider {
      padding: 0;
    }
    // flexSlider
    .flexslider {
      .slides img {
        height: auto !important;
      }
      border:0;
      border-radius:0;
      .slides .slide .slide-overlay {
        
        padding: 5%55%8%5%;
        @media only screen and (max-width: 768px){
          padding: 5%;
          
        }
        
        
        background: rgba(#000000,.5);
        
        
        background: -webkit-linear-gradient(top, rgba(#000000,0) , rgba(#000000,.5)); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(bottom, rgba(#000000,0) , rgba(#000000,.5)); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(bottom, rgba(#000000,0) , rgba(#000000,.5)); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to bottom, rgba(#000000,0) , rgba(#000000,.5)); /* Standard syntax (must be last) */
        
        bottom: 0;
      }
      .slideTextWrapper {
        position: relative;
        background-color: transparent;
        
        padding: 0px;
        
          // accent bar
          &:before {
            content: '';
            width: 100px;
            height: 8px;
            background: #FF030B;
            position: absolute;
            
            @media only screen and (max-width: 768px){ display: none; }
          }
          // indicator
          &:after {
            content: '';
            height: 0;
            width: 0;
            border: 6px solid transparent;
            border-top-color:transparent;
            border-right-color:transparent;
            border-bottom-color:transparent;
            position: absolute;
            display: inline-block;
            @media only screen and (max-width: 768px){ display: none; }
          }
          
          
            &:before {
              bottom: calc(100% + 10px);
              left: 0;
            }
            &:after {
              content: none;
            }
          
        
        &.no-text {
          background-color: transparent;
          padding: 0;

          &:before,
          &:after { display: none; }

        }
      }
      .slide-title,
      .slide-description {
        margin: 0;
      }
      .slide-title {
        font-family: Source Sans Pro, sans-serif;
        font-weight: bold;
        font-size: 45px;
        color: white;
        text-transform: uppercase;
        text-align: inherit;
        text-shadow: none;
        line-height: 1em;
        @media screen and (max-width:1024px) {
          font-size: 22.5px;
        }
        @media screen and (max-width:768px) {
          font-size: 18px;
        }
      }
      .slide-description {
        font-family: Source Sans Pro, sans-serif;
        font-weight: bold;
        font-size: 24px;
        color: white;
        text-transform: inherit;
        text-align: inherit;
        text-shadow: none;
        line-height: inherit;
        @media screen and (max-width:1024px) {
          font-size: 12px;
        }
      }
    }
  }
}
.has-home-media-slider #siteContainer {
  padding-top:0;
}
